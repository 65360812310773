import { X } from "lucide-solid";
import { Component } from "solid-js";
import { useTransferForm } from "../TransferFormProvider";
import { PriceOptionsDiscount } from "./PriceOptionsDiscount";

type Props = {};

export const SimplePriceOptionsOverlay: Component<Props> = (props) => {
  const [transferForm, setTransferForm] = useTransferForm();

  return (
    <div
      style={{
        position: "absolute",
        inset: 0,
        "z-index": 4,
        "background-color": "white",
        transition: "transform 150ms ease-in-out",
        transform: `translateY(${transferForm.priceOptionsVisible ? "0" : "100%"})`,
        overflow: "hidden",
        padding: "6px",
      }}
    >
      <div class="position-relative d-flex gap-2 justify-content-center align-items-center h-100">
        <X
          class="position-absolute cursor-pointer"
          style={{ top: "6px", right: "6px" }}
          onclick={() => setTransferForm("priceOptionsVisible", false)}
        />
        <PriceOptionsDiscount />
      </div>
    </div>
  );
};
