import { z } from "./openapizod";
import * as Shared from "./shared.api.schema";

export const routeLeg = z.object({
  linestring: Shared.GeoJson.linestring,
  duration: z.number(),
  distance: z.number(),
  ascend: z.number(),
  descend: z.number(),
});

export type RouteLeg = z.infer<typeof routeLeg>;

const baseResponseBody = routeLeg.extend({
  id: z.string(),
  created_at: z.string(),
  bbox: z.array(Shared.GeoJson.coordinates).min(2).max(2),
  legs: z.array(routeLeg),
  waypoints: z.array(Shared.GeoJson.coordinates).min(2),
  snapped_waypoints: z.array(Shared.GeoJson.coordinates).min(2),
  return_route_id: z.string().optional(),
});

export const responseBody: z.ZodType<ResponseBody> = routeLeg.extend({
  id: z.string(),
  created_at: z.string(),
  bbox: z.array(Shared.GeoJson.coordinates).min(2).max(2),
  legs: z.array(routeLeg),
  waypoints: z.array(Shared.GeoJson.coordinates).min(2),
  snapped_waypoints: z.array(Shared.GeoJson.coordinates).min(2),
  return_route_id: z.string().optional(),
  return_route: z.lazy(() => responseBody).optional(),
});

export type ResponseBody = z.infer<typeof baseResponseBody> & {
  return_route?: ResponseBody;
};

export namespace Get {
  export const requestParams = z.object({ id: z.string() });
  export type RequestParams = z.infer<typeof requestParams>;
}

export namespace Post {
  export const requestBody = z.object({
    coordinates: z.array(Shared.GeoJson.coordinates.max(2)).min(2),
    with_return_route: z.boolean().default(false).optional(),
  });
  export type RequestBody = z.infer<typeof requestBody>;
}
