import { ct } from "components";
import { Component, For, Show } from "solid-js";
import { availableTransportcompanies, fetchTransportcompanies } from "supabase-client";
import {
  isSelectedTransportcompany,
  selectedTransportcompany,
  setSelectedTransportcompanyId,
} from "../services/state/transportcompanies.state";

const TransportcompanySelectorC: Component = () => {
  return (
    <>
      <button
        style={{ "font-size": "14px" }}
        class="btn btn-light dropdown-toggle w-100 d-flex align-items-center gap-1 p-0"
        data-bs-toggle="dropdown"
        {...{
          "on:show.bs.dropdown": () => fetchTransportcompanies(),
        }}
      >
        <i class="bi-building" />
        <div class="text-truncate">{selectedTransportcompany()?.name ?? ct.vehicleSelection.noCompany()}</div>
      </button>
      <ul class="dropdown-menu dropdown-menu-end">
        <Show when={availableTransportcompanies().length}>
          <li>
            <button
              class="dropdown-item"
              classList={{ active: !selectedTransportcompany() }}
              onclick={() => setSelectedTransportcompanyId(undefined)}
            >
              {ct.vehicleSelection.noCompany()}
            </button>
          </li>
        </Show>
        <For
          each={availableTransportcompanies()}
          fallback={
            <li>
              <div class="mx-4 text-muted">{ct.vehicleSelection.noCompanies()}</div>
            </li>
          }
        >
          {(transportcompany) => (
            <li>
              <button
                class="dropdown-item"
                classList={{ active: isSelectedTransportcompany(transportcompany) }}
                onclick={() => setSelectedTransportcompanyId(transportcompany.id)}
              >
                {transportcompany.name}
              </button>
            </li>
          )}
        </For>
      </ul>
    </>
  );
};

export default TransportcompanySelectorC;
