import type * as API from "mooovex-api-schema";
import { Component } from "solid-js";
import { SolidMapLayer, SolidMapSource } from "solid-map";

type Props = {
  features: API.Shared.GeoJson.Feature[];
};

export const MapTaxistations: Component<Props> = (props) => {
  return (
    <>
      <SolidMapSource
        sourceSpecification={{ type: "geojson", data: { type: "FeatureCollection", features: props.features } }}
      >
        <SolidMapLayer
          layerSpecification={{
            type: "circle",
            filter: ["!=", "true", "disabled"],
            paint: {
              "circle-radius": 15,
              "circle-color": ["get", "color"],
              "circle-opacity": ["get", "circleOpacity"],
              "circle-stroke-width": 1,
              "circle-stroke-color": ["get", "color"],
              "circle-stroke-opacity": 1,
              "circle-pitch-alignment": "map",
              "circle-pitch-scale": "map",
            },
            minzoom: 13,
          }}
        />
        <SolidMapLayer
          layerSpecification={{
            type: "symbol",
            filter: ["!=", "true", "disabled"],
            paint: {
              "text-color": "black",
              "text-halo-color": "white",
              "text-halo-width": 1,
              "text-opacity": ["get", "textOpacity"],
            },
            layout: {
              "text-field": ["get", "name"],
              "text-size": 12,
            },
            minzoom: 13,
          }}
        />
      </SolidMapSource>
    </>
  );
};
