import { captureException } from "@sentry/browser";
import { debounce } from "@solid-primitives/scheduled";
import mooovexApiClient from "mooovex-api-client";
import * as API from "mooovex-api-schema";
import { Component, Show, createEffect, createSignal, onCleanup } from "solid-js";
import { ct, currentLocale } from "../i18n";
import { useTransferForm } from "../TransferFormProvider";
import { PlaceSearchResultList } from "./placeSearch.common";
type Props = {};

export const PlaceSearchOverlay: Component<Props> = (props) => {
  const [transferForm, , { showErrorMessage, closePlaceSearchOverlay, setPlaceToCurrentLocation }] = useTransferForm();

  async function autocomplete(value: string) {
    if (value.length < 3) {
      setPlaces(undefined);
      return;
    }

    try {
      const result = await mooovexApiClient.place.autocomplete(value, currentLocale());
      setPlaces(result);
    } catch (error) {
      setPlaces(undefined);
      showErrorMessage(ct.place.failedToGetPlaces());
      captureException(error);
    }
  }

  const debouncedAutocomplete = debounce(autocomplete, 250);
  const [places, setPlaces] = createSignal<API.google_autocomplete.ResponseBodyItem[]>();

  function onEscapeKey(e: KeyboardEvent) {
    if (e.key === "Escape") {
      closePlaceSearchOverlay();
    }
  }

  onCleanup(() => {
    removeEventListener("keyup", onEscapeKey);
  });

  createEffect(() => {
    if (transferForm.placeSearchVisible) {
      transitionElementRef.addEventListener("transitionend", onTransitionEnd, { once: true });
      addEventListener("keyup", onEscapeKey);
    } else {
      transitionElementRef.removeEventListener("transitionend", onTransitionEnd);
      removeEventListener("keyup", onEscapeKey);
      debouncedAutocomplete.clear();
      setPlaces(undefined);
      if (inputRef) inputRef.value = "";
    }
  });

  function onTransitionEnd() {
    inputRef.focus();
  }

  let inputRef!: HTMLInputElement;
  let transitionElementRef!: HTMLDivElement;

  return (
    <div
      ref={transitionElementRef}
      class="d-flex flex-column"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        "z-index": 70,
        "background-color": "white",
        transition: "transform 150ms ease-in-out",
        transform: `translateY(${transferForm.placeSearchVisible ? "0" : "100%"})`,
        overflow: "hidden",
      }}
    >
      <Show when={transferForm.form.placeSearchTargetContainerId}>
        {(currentContainerId) => (
          <>
            <div class="input-group shadow p-2">
              <button class="btn btn-border-dark bi-arrow-left" onclick={closePlaceSearchOverlay} />
              <input
                type="text"
                class="form-control form-control-lg"
                ref={inputRef}
                oninput={(e) => debouncedAutocomplete(e.currentTarget.value)}
              />
            </div>
            <div class="flex-grow-1 overflow-scroll px-2">
              <hr />
              <div
                class="d-flex align-items-center cursor-pointer"
                onclick={() => {
                  setPlaceToCurrentLocation(currentContainerId());
                  closePlaceSearchOverlay();
                }}
              >
                <i class="bi-bullseye me-2" />
                <span class="fw-bold">{ct.ride.currentLocation()}</span>
              </div>
              <hr />
              <Show
                when={places()}
                fallback={
                  <>
                    <Show when={transferForm.placeSearchFavorites.length}>
                      <div class="text-muted lh-1 small text-center">
                        <i class="bi-star-fill me-2" />
                        {ct.place.search.favorites()}
                      </div>
                      <PlaceSearchResultList
                        places={transferForm.placeSearchFavorites}
                        containerId={currentContainerId()}
                        favorites={transferForm.placeSearchFavorites}
                        compact
                      />
                      <hr />
                    </Show>
                    <Show when={transferForm.placeSearchHistory.length}>
                      <div class="text-muted lh-1 small text-center">
                        <i class="bi-clock-history" />
                        &nbsp;{ct.place.search.history()}
                      </div>
                      <PlaceSearchResultList
                        places={transferForm.placeSearchHistory}
                        containerId={currentContainerId()}
                        favorites={transferForm.placeSearchFavorites}
                        compact
                      />
                      <hr />
                    </Show>
                  </>
                }
              >
                {(places) => (
                  <>
                    <div class="text-muted lh-1 small text-center">
                      <i class="bi-search" />
                      &nbsp;
                      <Show when={places().length} fallback={ct.common.noResults()}>
                        {ct.place.search.results()}
                      </Show>
                    </div>
                    <PlaceSearchResultList
                      places={places()}
                      containerId={currentContainerId()}
                      favorites={transferForm.placeSearchFavorites}
                    />
                    <hr />
                  </>
                )}
              </Show>
            </div>
          </>
        )}
      </Show>
    </div>
  );
};
