import { captureException } from "@sentry/browser";
import mooovexApiClient from "mooovex-api-client";
import * as API from "mooovex-api-schema";
import { Component } from "solid-js";
import { ct, currentLocale } from "../i18n";
import { useTransferForm } from "../TransferFormProvider";
import { PlaceSearchIcon } from "./placeSearch.common";

type Props = {
  place: API.google_autocomplete.ResponseBodyItem;
  containerId: string;
  isFavorited?: boolean;
  compact?: boolean;
};

async function autocompleteItemToPlace(autocompleteItem: API.google_autocomplete.ResponseBodyItem) {
  const result = await mooovexApiClient.place.get(autocompleteItem.google_place_id, currentLocale());
  result.name = autocompleteItem.name;
  result.formatted_address = autocompleteItem.formatted_address;
  return result;
}

export const PlaceSearchAutocompleteItem: Component<Props> = (props) => {
  const [transferForm, setTransferForm, { showErrorMessage, setPlaceContainer }] = useTransferForm();
  const address = () => API.PlaceAdapter.getAddress(props.place);

  function addToPlaceSearchHistory(place: API.google_autocomplete.ResponseBodyItem) {
    if (transferForm.placeSearchHistory.some((p) => p.google_place_id === place.google_place_id)) return;
    setTransferForm("placeSearchHistory", (prev) => [place, ...prev].slice(0, 5));
  }

  function addToPlaceFavorites(place: API.google_autocomplete.ResponseBodyItem) {
    setTransferForm("placeSearchFavorites", (prev) => [place, ...prev]);
  }

  function removeFromPlaceFavorites(id: number | string) {
    setTransferForm("placeSearchFavorites", (prev) => prev.filter((p) => p.google_place_id !== id));
  }

  return (
    <>
      <div
        class="d-flex align-items-center gap-2 cursor-pointer"
        onclick={async () => {
          try {
            const place = await autocompleteItemToPlace(props.place);
            setPlaceContainer(props.containerId, place);
            setTransferForm("placeSearchVisible", false);
            addToPlaceSearchHistory(props.place);
          } catch (error) {
            showErrorMessage(ct.place.failedToGetDetails());
            captureException(error);
          }
        }}
      >
        <PlaceSearchIcon place={props.place} />
        <div
          class="flex-grow-1"
          classList={{
            "text-nowrap": props.compact,
            "text-truncate": props.compact,
          }}
        >
          <span class="fw-bold">{props.place.name}</span>&nbsp;<span class="text-muted">{address()}</span>
        </div>
        <i
          onclick={(e) => {
            e.stopPropagation();
            if (props.isFavorited) {
              removeFromPlaceFavorites(props.place.google_place_id);
            } else {
              addToPlaceFavorites(props.place);
            }
          }}
          class="text-primary"
          classList={{
            "bi-star": !props.isFavorited,
            "bi-star-fill": props.isFavorited,
          }}
        />
      </div>
    </>
  );
};
