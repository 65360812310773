import { LngLatLike, Marker, MarkerOptions, Popup, PopupOptions } from "maplibre-gl";
import {
  createContext,
  createEffect,
  createSignal,
  FlowProps,
  JSX,
  onCleanup,
  onMount,
  ParentProps,
  Show,
  useContext,
} from "solid-js";
import { render } from "solid-js/web";
import { useSolidMap } from "./SolidMapProvider";

const SolidMapMarkerContext = createContext<Marker>();

function SolidMapMarkerProvider(props: ParentProps<{ marker: Marker }>) {
  return <SolidMapMarkerContext.Provider value={props.marker}>{props.children}</SolidMapMarkerContext.Provider>;
}

export function useSolidMapMarker() {
  const marker = useContext(SolidMapMarkerContext);
  if (!marker) throw new Error("You didn't use useSolidMapMarker inside a SolidMapMarkerProvider");
  return marker;
}

export default function SolidMapMarker(
  localProps: FlowProps<{
    lngLat: LngLatLike;
    markerOptions?: Omit<MarkerOptions, "element">;
    popupElement?: JSX.Element;
    popupOptions?: PopupOptions;
    onInit?: (marker: Marker) => void;
  }>
) {
  let markerElementRef = document.createElement("div");
  let popupElementRef = document.createElement("div");

  const [marker, setMarker] = createSignal<Marker>();

  const map = useSolidMap();

  const cleanupMarkerRender = render(
    () => (
      <Show when={marker()}>
        {(marker) => <SolidMapMarkerProvider marker={marker()}>{localProps.children}</SolidMapMarkerProvider>}
      </Show>
    ),
    markerElementRef
  );

  const cleanupPopupRender = render(() => localProps.popupElement, popupElementRef);

  onMount(() => {
    const newMarker = new Marker({
      element: markerElementRef,
      ...localProps.markerOptions,
    });

    if (localProps.popupElement) {
      const newPopup = new Popup(localProps.popupOptions);
      newPopup.setDOMContent(popupElementRef.firstChild!);
      newMarker.setPopup(newPopup);
    }

    newMarker.setLngLat(localProps.lngLat);
    newMarker.addTo(map);
    setMarker(newMarker);
    localProps.onInit?.(newMarker);
  });

  createEffect(
    () => {
      marker()?.setLngLat(localProps.lngLat);
    },
    { defer: true }
  );

  createEffect(
    () => {
      marker()?.getPopup()?.setOffset(localProps.popupOptions?.offset);
    },
    { defer: true }
  );

  onCleanup(() => {
    cleanupPopupRender();
    cleanupMarkerRender();
    marker()?.remove();
  });

  return null;
}
