import { z } from "zod";
import { MyDate } from "./MyDate.class";
import { MyTime } from "./MyTime.class";
import { Clonable, Comparable, Serializable } from "./utils";

export class MyTimestamp implements Clonable<MyTimestamp>, Serializable<string>, Comparable {
  static serialized = z.string().datetime({ offset: true });

  private constructor(date: Date) {
    this.date = date;
  }

  private date: Date;

  static fromJSON(value: string): MyTimestamp {
    return MyTimestamp.fromDate(new Date(value));
  }

  static fromMyDateAndMyTime(date: MyDate, time: MyTime): MyTimestamp {
    return MyTimestamp.fromDate(new Date(date.toString() + "T" + time.toString()));
  }

  static now(): MyTimestamp {
    return new MyTimestamp(new Date());
  }

  static fromDate(date: Date): MyTimestamp {
    return new MyTimestamp(date);
  }

  toDate(): Date {
    return new Date(this.date);
  }

  toString(): string {
    return this.date.toISOString();
  }

  clone(): MyTimestamp {
    return new MyTimestamp(this.date);
  }

  valueOf(): number {
    return this.date.valueOf();
  }

  toJSON(): string {
    return this.toString();
  }

  getMyDate(): MyDate {
    return MyDate.fromDate(this.date);
  }

  getMyTime(): MyTime {
    return MyTime.fromDate(this.date);
  }
}
