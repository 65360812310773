import { ApiClientError, bearerClient, client } from "./client";
import * as place from "./place";
import * as price from "./price";
import * as route from "./route";
export type { InferRequestType, InferResponseType } from "hono/client";

export default {
  ApiClientError,
  client,
  place,
  route,
  price,
  bearerClient,
};
