import * as API from "mooovex-api-schema";
import { Component, For, Show, createEffect, createSignal, mergeProps } from "solid-js";
import { MyButton } from "./MyButton";
import { ct } from "./i18n";

export function createPlaceSearchBox(options?: Partial<SearchBoxOptions>) {
  const opts: SearchBoxOptions = {
    onChoose: options?.onChoose,
    onSearch: options?.onSearch ?? (async () => []),
    onCurrentLocation: options?.onCurrentLocation,
    onOpenInGoogleMaps: options?.onOpenInGoogleMaps,
    debounce: 300,
  };

  const clear = () => {
    setInputText({ text: "", fetch: false });
    setPlaces([]);
    opts.onChoose?.(undefined);
  };

  async function getCurrentLocation() {
    if (opts.onCurrentLocation) {
      setIsGpsSearching(true);
      try {
        const place = await opts.onCurrentLocation();
        setInputText({ text: place.name, fetch: false });
        setInputFocused(false);
      } catch (error) {
        console.error(error);
      } finally {
        setIsGpsSearching(false);
      }
    }
  }

  function choosePlace(place: API.google_autocomplete.ResponseBodyItem | undefined) {
    if (place) {
      setInputText({ text: place.name, fetch: false });
    } else {
      clear();
    }
    setInputFocused(false);
    opts.onChoose?.(place);
  }

  function setPlace(place: API.google_place_details.ResponseBody | undefined) {
    if (place) {
      setInputText({ text: place.name, fetch: false });
    } else {
      clear();
    }
    setInputFocused(false);
  }

  const [isGpsSearching, setIsGpsSearching] = createSignal<boolean>(false);
  const [inputText, setInputText] = createSignal<{ text: string; fetch: boolean }>({ text: "", fetch: false });
  const [inputFocused, setInputFocused] = createSignal(false);
  const [requestState, setRequestState] = createSignal<"ready" | "refreshing" | "errored" | "unresolved">("ready");
  const [places, setPlaces] = createSignal<API.google_autocomplete.ResponseBodyItem[]>([]);
  const [debouncedSearch, cancelSearch] = debounce(async (text: string) => {
    try {
      setRequestState("refreshing");
      const results = await opts.onSearch(text);
      setRequestState("ready");
      setPlaces(results);
    } catch (error) {
      setRequestState("errored");
    }
  }, opts.debounce);

  const SearchBoxComponent: Component<Partial<SearchBoxProps>> = (partialProps) => {
    const props = mergeProps<[SearchBoxProps, Partial<SearchBoxProps>]>(
      {
        placeholder: "",
        disabled: false,
        autocompleteListPosition: "bottom",
      },
      partialProps
    );

    createEffect(() => {
      if (inputText().fetch && inputText().text.length) {
        debouncedSearch(inputText().text);
      } else {
        cancelSearch();
        setRequestState("unresolved");
        setPlaces([]);
      }
    });

    let isMouseOnResults = false;
    let inputRef!: HTMLInputElement;

    return (
      <div class="position-relative">
        <div class="input-group">
          <Show when={options?.onCurrentLocation && !props.disabled}>
            <MyButton
              color="primary"
              variant="border"
              iconClass="bi-bullseye"
              spinner="grow"
              onclick={getCurrentLocation}
              disabled={props.disabled}
              loading={isGpsSearching()}
            />
          </Show>
          <input
            ref={inputRef}
            type="text"
            class="form-control"
            placeholder={props.placeholder}
            value={inputText().text}
            oninput={(e) => setInputText({ text: e.currentTarget.value, fetch: true })}
            onFocusIn={() => setInputFocused(true)}
            onFocusOut={() => {
              if (!isMouseOnResults) {
                setInputFocused(false);
              }
            }}
            disabled={props.disabled}
          />
          <Show when={!props.disabled && inputText().text.length}>
            <MyButton
              color="dark"
              variant="border"
              iconClass="bi-x-lg"
              spinner="border"
              onclick={clear}
              disabled={props.disabled}
              loading={requestState() === "refreshing"}
            />
          </Show>
          <Show when={options?.onOpenInGoogleMaps}>
            <MyButton color="dark" variant="border" iconClass="bi-cursor-fill" onclick={options?.onOpenInGoogleMaps} />
          </Show>
        </div>
        <Show when={inputFocused() && requestState() === "ready" && inputText().text.length}>
          <div
            class="position-absolute start-0 end-0 mt-1 shadow"
            style="z-index: 600;"
            classList={{
              "top-100": props.autocompleteListPosition === "bottom",
              "bottom-100": props.autocompleteListPosition === "top",
            }}
          >
            <ul class="list-group overflow-auto" style="max-height: 300px">
              <Show when={requestState() === "ready" && inputText().text.length}>
                <li class="list-group-item">
                  <div class="text-muted lh-1 small">{ct.place.search.results()}</div>
                </li>
                <For
                  each={places()}
                  fallback={
                    <li class="list-group-item">
                      <div class="text-muted lh-1 small">{ct.common.noResults()}</div>
                    </li>
                  }
                >
                  {(place) => {
                    const address = () => API.PlaceAdapter.getAddress(place);
                    return (
                      <button
                        class="list-group-item list-group-item-action placeListItem"
                        tabindex={-1}
                        onclick={() => {
                          choosePlace(place);
                          inputRef.blur();
                          isMouseOnResults = false;
                        }}
                        onPointerDown={(e) => {
                          e.preventDefault();
                          isMouseOnResults = true;
                        }}
                        onPointerUp={(e) => {
                          e.preventDefault();
                          isMouseOnResults = false;
                        }}
                        disabled={props.disabled}
                      >
                        <div class="w-100 d-flex justify-content-between">
                          <div class="fw-bold">{place.name}</div>
                        </div>
                        <div class="text-muted lh-1 small">{address()}</div>
                      </button>
                    );
                  }}
                </For>
              </Show>
            </ul>
          </div>
        </Show>
      </div>
    );
  };

  return [SearchBoxComponent, { clear, inputFocused, getCurrentLocation, choosePlace, setPlace }] as const;
}

function debounce<T extends Array<any>>(fn: ((...args: T) => void) | (() => void), delay: number) {
  let timeoutId: ReturnType<typeof setTimeout>;

  return [
    function debounced(...args: T) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn(...args), delay);
    },
    () => clearTimeout(timeoutId),
  ] as const;
}

export type SearchBoxOptions = {
  onChoose?: (place: API.google_autocomplete.ResponseBodyItem | undefined) => void;
  onSearch: (searchText: string) => Promise<API.google_autocomplete.ResponseBodyItem[]>;
  onCurrentLocation?: () => Promise<API.google_place_details.ResponseBody>;
  onOpenInGoogleMaps?: () => void;
  debounce: number;
};

export type SearchBoxProps = {
  placeholder: string;
  autocompleteListPosition: "top" | "bottom";
  disabled: boolean;
};
