import { ct, useDriverlocations } from "components";
import { Component, For } from "solid-js";
import classes from "./ClientDriverlist.module.scss";
type Props = {
  onDriverClick?: (driverId: string) => void;
};

export const ClientDriverlist: Component<Props> = (props) => {
  const { driverlocationsArray } = useDriverlocations();

  const zipcodes = () => {
    const zipcodesSet = new Set<number>();
    const zipcodesArray: Array<{ zipcodeId: number; zipcode: string; municipiality: string }> = [];

    for (const driverlocation of driverlocationsArray()) {
      if (!zipcodesSet.has(driverlocation.vehicleZipcodeId)) {
        zipcodesSet.add(driverlocation.vehicleZipcodeId);
        zipcodesArray.push({
          zipcodeId: driverlocation.vehicleZipcodeId,
          zipcode: driverlocation.vehicleZipcode,
          municipiality: driverlocation.vehicleMunicipiality,
        });
      }
    }

    return zipcodesArray.sort((a, b) => parseInt(a.zipcode) - parseInt(b.zipcode));
  };

  return (
    <table class={classes.table}>
      <For each={zipcodes()} fallback={<div class="text-center">{ct.driverlist.noDrivers()}</div>}>
        {(zipcode) => {
          const zipcodeDrivers = () =>
            driverlocationsArray()
              .filter((driverlocation) => driverlocation.vehicleZipcodeId === zipcode.zipcodeId)
              .sort((a, b) => parseInt(a.vehicleIdentifier) - parseInt(b.vehicleIdentifier));

          return (
            <>
              <thead>
                <tr>
                  <th></th>
                  <th class={classes.municipiality}>{zipcode?.municipiality ?? ct["driverlist.noZipcode"]()}</th>
                  <th class="text-center">
                    <i class="bi-people-fill" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <For each={zipcodeDrivers()}>
                  {(driver) => {
                    return (
                      <>
                        <tr class="cursor-pointer align-middle">
                          <td onclick={() => props.onDriverClick?.(driver.driverId)}>
                            <div class={classes.badge} classList={{ [classes[driver.status]]: true }}>
                              {driver.vehicleIdentifier}
                            </div>
                          </td>
                          <td
                            class={classes.name}
                            title={driver.driverName}
                            onclick={() => props.onDriverClick?.(driver.driverId)}
                          >
                            {driver.driverName}
                          </td>
                          <td class={classes.seats} onclick={() => props.onDriverClick?.(driver.driverId)}>
                            {driver.vehicleSeats}
                          </td>
                          <td>
                            <i
                              class="bi-telephone-fill text-primary cursor-pointer"
                              title={`${driver.phoneNumber} (${ct["common.rightClickToCopy"]()})`}
                              onclick={() => window.open(`tel:${driver.phoneNumber}`)}
                              oncontextmenu={async (e) => {
                                e.preventDefault();
                                await navigator.clipboard.writeText(driver.phoneNumber);
                              }}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  }}
                </For>
              </tbody>
            </>
          );
        }}
      </For>
    </table>
  );
};
