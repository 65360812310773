import { createTimeDifferenceFromNow } from "@solid-primitives/date";
import { MyTimestamp } from "helpers";
import { Component } from "solid-js";
import { Driverlocation } from "../DriverlocationsProvider";
import { F } from "../formatters";
import { ct } from "../i18n";
import { IdentifierBadge, LicensePlate } from "../vehicles";

type Props = {
  driverlocation: Driverlocation;
};

export const MapDriverMarkerPopup: Component<Props> = (props) => {
  const formattedSpeed = () => F.kmh(props.driverlocation.speed * 3.6);
  const timestampMs = () =>
    props.driverlocation.timestamp
      ? MyTimestamp.fromJSON(props.driverlocation.timestamp).toDate().valueOf()
      : Date.now();
  const [timestampDiffMs] = createTimeDifferenceFromNow(timestampMs, 1000);
  const formattedRelativeTime = () => F.relativeTime(timestampDiffMs());

  return (
    <div class="fs-6 d-flex flex-column gap-1 placeholder-glow">
      <div class="d-flex align-items-center justify-content-between gap-2 nowrap">
        <IdentifierBadge value={props.driverlocation.vehicleIdentifier} status={props.driverlocation.status} />
        <div class="fw-bold text-truncate">{props.driverlocation.driverName}</div>
        <button
          class="btn btn-light btn-sm"
          title={`${props.driverlocation.phoneNumber} (${ct["common.rightClickToCopy"]()})`}
          onclick={() => window.open(`tel:${props.driverlocation.phoneNumber}`)}
          oncontextmenu={async (e) => {
            e.preventDefault();
            await navigator.clipboard.writeText(props.driverlocation.phoneNumber);
          }}
        >
          <i class="bi-telephone-fill text-primary" />
        </button>
      </div>
      <div class="d-flex justify-content-between align-items-center gap-2 nowrap">
        <div class="text-truncate">{props.driverlocation.vehicleName}</div>
        <LicensePlate value={props.driverlocation.vehicleLicensePlate} />
      </div>
      <div class="d-flex justify-content-between gap-2 nowrap">
        <div>{formattedSpeed()}</div>
        <div class="text-muted">{formattedRelativeTime()}</div>
      </div>
    </div>
  );
};
