import { captureException } from "@sentry/browser";
import { Key } from "@solid-primitives/keyed";
import { debounce } from "@solid-primitives/scheduled";
import { NewMap, NewPlaceSearch, RideForm, ct, currentLocale, useDriverlocations } from "components";
import { driverMarkerInstances } from "components/src/map/MapDriverMarker";
import {
  PassengerCountButton,
  PassengerCountSelectorOverlay,
  SimplePriceOptionsOverlay,
} from "components/src/rideForm/rideForm.common";
import { useTransferForm } from "components/src/TransferFormProvider";
import { MyDate, MyTime, MyTimestamp } from "helpers";
import mooovexApiClient from "mooovex-api-client";
import * as API from "mooovex-api-schema";
import { Component, For, Show, createEffect, on, onCleanup, onMount } from "solid-js";
import {
  currentRoles,
  liveTaxistationsMap,
  subscribeToLiveTaxistations,
  toGeoJSON,
  unsubscribeFromLiveTaxistations,
} from "supabase-client";
import { ClientDriverlist } from "../components/ClientDriverlist";
import { Datepicker } from "../components/Datepicker";
import { TaxistationsC } from "../components/Taxistations.c";
import TransportcompanySelectorC from "../components/TransportcompanySelector.c";
import { selectedTransportcompanyId } from "../services/state/transportcompanies.state";

const DriversPage: Component = () => {
  const [transferForm, setTransferForm, { placesInWaypoints, showErrorMessage }] = useTransferForm();
  const { driverlocationsArray, startPolling, stopPolling } = useDriverlocations();

  const taxistationFeatures = () =>
    Array.from(liveTaxistationsMap.values())
      .filter((station) => !station.disabled)
      .map(
        (station) =>
          ({
            type: "Feature",
            geometry: toGeoJSON(station.location),
            properties: {
              disabled: station.disabled,
              color: station.active ? "#002672" : "gray",
              circleOpacity: station.active ? 0.2 : 0.1,
              textOpacity: station.active ? 1 : 0.6,
              name: station.name,
            },
          }) satisfies API.Shared.GeoJson.Feature
      );

  onMount(async () => {
    await subscribeToLiveTaxistations();
    startPolling();
  });

  onCleanup(async () => {
    await unsubscribeFromLiveTaxistations();
    stopPolling();
  });

  const debouncedRouteCalculation = debounce(async () => {
    if (placesInWaypoints().length >= 2) {
      setTransferForm("form", "route", undefined);

      const date = transferForm.form.startDate;
      const time = transferForm.form.startTime;
      const timestamp = MyTimestamp.fromMyDateAndMyTime(date ?? MyDate.today(), time ?? MyTime.now());

      try {
        setTransferForm("form", "routeLoading", true);
        const route = await mooovexApiClient.route.compute(
          placesInWaypoints().map(API.PlaceAdapter.getCoordinates),
          timestamp,
          selectedTransportcompanyId()
        );
        setTransferForm("form", "route", route);
      } catch (error) {
        captureException(error);
        showErrorMessage(ct.ride.route.calculation.error());
      } finally {
        setTransferForm("form", "routeLoading", false);
      }
    } else {
      setTransferForm("form", "route", undefined);
    }
  }, 100);

  const debouncedPriceCalculation = debounce(async () => {
    if (transferForm.form.route && transferForm.form.passengerCount !== undefined) {
      setTransferForm("form", "price", undefined);
      const date = transferForm.form.startDate;
      const time = transferForm.form.startTime;
      const timestamp = MyTimestamp.fromMyDateAndMyTime(date ?? MyDate.today(), time ?? MyTime.now());

      try {
        setTransferForm("form", "priceLoading", true);
        const price = await mooovexApiClient.price.compute({
          routeId: transferForm.form.route.id,
          passengerCount: transferForm.form.passengerCount,
          discount: transferForm.form.discount,
          timestamp,
          transportcompanyId: selectedTransportcompanyId(),
        });
        setTransferForm("form", "price", price.price);
      } catch (error) {
        captureException(error);
        showErrorMessage(ct.price.calculation.error());
        setTransferForm("form", "price", undefined);
      } finally {
        setTransferForm("form", "priceLoading", false);
      }
    } else {
      setTransferForm("form", "price", undefined);
    }
  }, 300);

  // Route Calclculation
  createEffect(on(placesInWaypoints, debouncedRouteCalculation));

  // Price calculation
  createEffect(
    on(
      [
        () => transferForm.form.route,
        () => transferForm.form.passengerCount,
        () => transferForm.form.startDate,
        () => transferForm.form.startTime,
        () => transferForm.form.discount,
        selectedTransportcompanyId,
      ],
      debouncedPriceCalculation
    )
  );

  return (
    <>
      <div class="d-flex h-100 overflow-x-hidden">
        <div class="d-flex flex-column flex-grow-1 h-100 overflow-hidden">
          <NewMap.MapComponent class="flex-grow-1">
            <NewMap.MapAnimations right={47} left={47} bottom={88} />
            <NewMap.MapControlGroup bottom={6} right={6}>
              <NewMap.MapZoomControl />
            </NewMap.MapControlGroup>
            <Show when={transferForm.form.route || transferForm.form.routeLoading}>
              <RideForm.RideInfo />
            </Show>
            <NewMap.MapTaxistations features={taxistationFeatures()} />
            <Key each={driverlocationsArray()} by={(driverlocation) => driverlocation.driverId}>
              {(driverlocation) => (
                <NewMap.MapDriverMarker
                  driverlocation={driverlocation()}
                  follow={driverlocation().driverId === transferForm.centeredDriverId}
                />
              )}
            </Key>
            <For each={placesInWaypoints()}>
              {(place, i) => (
                <NewMap.MapPlaceMarker
                  lngLat={API.PlaceAdapter.getCoordinates(place)}
                  index={i()}
                  count={placesInWaypoints().length}
                />
              )}
            </For>
            <Show when={transferForm.form.route}>
              {(route) => (
                <>
                  <NewMap.MapLineString linestring={route().linestring} />
                  <Show when={route().return_route}>
                    {(return_route) => (
                      <NewMap.MapLineString linestring={return_route().linestring} style="return_route" />
                    )}
                  </Show>
                </>
              )}
            </Show>
          </NewMap.MapComponent>
          <div class="d-flex flex-column gap-1 p-1 position-relative">
            <RideForm.DragAndDropPlaces />
            <div class="d-flex gap-2">
              <RideForm.PlaceAddButton maxPlaceCount={5} />
              <PassengerCountButton />
              <Datepicker
                locale={currentLocale()}
                clearButton
                placeholder={ct.common.date()}
                datepicker={true}
                timepicker={false}
                position="top left"
                onSelect={(timestamp) => setTransferForm("form", "startDate", timestamp?.getMyDate())}
                value={/* @once */ transferForm.form.startDate?.getMyTimestamp()}
              />
              <Datepicker
                locale={currentLocale()}
                clearButton
                placeholder={ct.common.time()}
                datepicker={false}
                timepicker={true}
                position="top left"
                onSelect={(timestamp) => setTransferForm("form", "startTime", timestamp?.getMyTime())}
                value={/* @once */ transferForm.form.startTime?.getMyTimestamp()}
              />
              <RideForm.ClearRideFormButton />
            </div>
            <PassengerCountSelectorOverlay min={1} max={10} />
            <SimplePriceOptionsOverlay />
          </div>
        </div>
        <NewPlaceSearch.PlaceSearchOverlay />
        <div style={{ width: "150px" }} class="p-2 h-100 overflow-y-auto overflow-x-hidden bg-white flex-shrink-0">
          <TransportcompanySelectorC />
          <hr />
          <Show when={currentRoles().some((role) => ["taxistations", "owner"].includes(role))}>
            <TaxistationsC />
            <hr />
          </Show>
          <ClientDriverlist
            onDriverClick={(driver_id) => {
              setTransferForm("centeredDriverId", driver_id);
              driverMarkerInstances.forEach((marker) => marker.getPopup()?.remove());
              const driverMarkerInstance = driverMarkerInstances.get(driver_id);
              if (driverMarkerInstance) {
                driverMarkerInstance.getPopup()?.addTo(driverMarkerInstance._map);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DriversPage;
