import { MyDate, MyTime } from "helpers";
import { BaseF, currentLocale } from "./i18n";

export namespace F {
  export const kmh = (arg: Parameters<typeof BaseF.kmh>[0]) => BaseF.kmh(arg, currentLocale());
  export const mps = (arg: Parameters<typeof BaseF.mps>[0]) => BaseF.mps(arg, currentLocale());
  export const humanDuration = (arg: Parameters<typeof BaseF.humanDuration>[0]) =>
    BaseF.humanDuration(arg, currentLocale());
  export const duration = (arg: Parameters<typeof BaseF.duration>[0]) => BaseF.duration(arg, currentLocale());
  export const kilometers = (arg: Parameters<typeof BaseF.kilometers>[0]) => BaseF.kilometers(arg, currentLocale());
  export const meters = (arg: Parameters<typeof BaseF.meters>[0]) => BaseF.meters(arg, currentLocale());
  export const euros = (arg: Parameters<typeof BaseF.euros>[0]) => BaseF.euros(arg, currentLocale());
  export const relativeTime = (arg: Parameters<typeof BaseF.relativeTime>[0]) =>
    BaseF.relativeTime(arg, currentLocale());
  export const date = (value: MyDate) =>
    new Intl.DateTimeFormat(currentLocale(), { day: "2-digit", month: "2-digit", year: "numeric" }).format(
      value.toDate()
    );
  export const time = (value: MyTime) => value.toString(2);
  export const percent = (value: number) =>
    new Intl.NumberFormat(currentLocale(), { style: "percent", signDisplay: "exceptZero" }).format(value);
}
