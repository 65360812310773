import type { Dict } from "./en";

export const dict: Dict = {
  account: {
    _: "Account",
    login: "Anmelden",
    register: "Registrieren",
    email: {
      _: "E-Mail",
      confirm: {
        title: "E-Mail bestätigen",
        message: "Ihr Konto wurde erstellt. Sie müssen Ihre E-Mail-Adresse bestätigen, bevor Sie sich anmelden können",
      },
    },
    password: "Passwort",
    passwordMinLength: (minLength: number) => `Das passwort muss mindestens ${minLength} Zeichen lang sein`,
    resetPassword: "Passwort zurücksetzen",
    passwordRepeat: "Passwort wiederholen",
    name: "Vorname",
    surname: "Nachname",
    phoneNumber: "Telefonnummer",
    passwordResetInstructions: "Wir senden Anweisungen zum Zurücksetzen Ihres Passworts an diese E-Mail",
    sixdigitcode: "6-Stelliger Code",
    codeVerifyFailed:
      "Die Verifizierung des Codes ist fehlgeschlagen. Dieser Code ist nur für einen begrenzten Zeitraum gültig und kann nur einmal verwendet werden. Bitte versuchen Sie es erneut.",
    logout: "Abmelden",
    logoutFail: "Abmeldung fehlgeschlagen",
    create: "Account erstellen",
    alreadyHave: "Ich besitze bereits einen account",
    statusBeforeLogout: "Stellen Sie Ihren Status auf 'außer Betrieb', bevor Sie sich abmelden",
    changePassword: "Passwort ändern",
    delete: {
      _: "Konto löschen",
      warning:
        "Nach Eingabe Ihres Passworts wird Ihr Konto zur Löschung markiert und von einem Administrator gelöscht. Diese Aktion kann nicht rückgängig gemacht werden",
      confirmation: {
        title: "Konto gelöscht",
        message:
          "Ihr Konto wurde zur Löschung markiert und wird von einem Administrator gelöscht. Wir senden Ihnen eine E-Mail, wenn dieser Vorgang abgeschlossen ist. Falls dies ein Fehler war, kontaktieren Sie uns bitte umgehend: info@mooovex.com",
      },
      error: "Fehler beim Löschen Ihres Kontos. Überprüfen Sie Ihr Passwort",
    },
    created: {
      title: "Danke, dass du dich bei Mooovex registriert hast",
      message: (email: string) =>
        `Um dein Konto zu aktivieren folge bitte den Anleitungen die du per E-Mail an ${email} erhalten hast`,
    },
  },
  validation: {
    passwordMinLength: "Das Passwort muss mindestens 8 Zeichen lang sein",
    emailInvalid: "Ungültige E-Mail",
    passwordsDontMatch: "Passwörter stimmen nicht überein",
    requiredField: "Erforderlich",
    invalidPhone: "Ungültige Telefonnummer",
    login: {
      error: "Anmeldung fehlgeschlagen, überprüfen Sie E-Mail und Passwort",
    },
    register: {
      error: "Registrierung fehlgeschlagen, versuchen Sie es später erneut",
      emailAlreadyExists:
        "Ein Konto mit dieser E-Mail-Adresse existiert bereits. Wenn Sie das Passwort vergessen haben, können Sie es zurücksetzen.",
    },
    passwordResetFail: "Passwortzurücksetzung fehlgeschlagen, überprüfen Sie die E-Mail",
    passwordResetSuccess: (email: string) =>
      `Wenn ein Konto mit der E-Mail-Adresse ${email} existiert, wurde eine E-Mail mit Anweisungen zum Zurücksetzen des Passworts an diese Adresse gesendet`,
    passwordChange: {
      error: "Passwortänderung fehlgeschlagen",
      success: "Dein Passwort wurde erfolgreich geändert",
    },
  },
  common: {
    troubleshooting: "Fehlerbehebung",
    noResults: "Keine Ergebnisse",
    allow: "Zulassen",
    cancel: "Abbrechen",
    continue: "Weiter",
    back: "Zurück",
    user: "Benutzer",
    company: "Unternehmen",
    companies: "Unternehmen",
    yourCompanies: "Deine Unternehmen",
    language: "Sprache",
    yes: "Ja",
    no: "Nein",
    ok: "OK",
    abort: "Abbrechen",
    date: "Datum",
    time: "Uhrzeit",
    default: "Standard",
    normal: "Normal",
    rightClickToCopy: "Rechtsklick zum Kopieren",
    tryAgain: "Erneut versuchen",
    error: "Fehler",
    close: "Schließen",
    save: "Speichern",
    name: "Name",
    email: "E-Mail",
    phoneNumber: "Telefonnummer",
    reset: "Zurücksetzen",
    delete: "Löschen",
    refresh: "Aktualisieren",
    loading: "Laden",
  },
  ride: {
    duration: "Dauer",
    distance: "Entfernung",
    notriptext: "Geben Sie den Start- und Endpunkt der Route ein, um die Details zu erhalten",
    reset: { _: "Fahrt zurücksetzen" },
    start: { _: "Fahrt beginnen" },
    conclude: {
      _: "Fahrt beenden",
      confirm: "Sind Sie sicher, dass Sie die Fahrt beenden wollen?",
      error: "Fahrt beenden fehlgeschalgen",
    },
    abort: {
      _: "Fahrt abbrechen",
      confirm: "Sind Sie sicher, dass Sie die Fahrt abbrechen wollen?",
    },
    wait: { _: "Wartezeit" },
    resume: { _: "Fahrt fortsetzen" },
    origin: {
      _: "Startpunkt",
      keep: {
        title: "Startpunkt bereits festgelegt",
        message:
          "Sie haben bereits einen Startpunkt festgelegt. Möchten Sie ihn behalten oder Ihren aktuellen Standort verwenden?",
      },
    },
    destination: {
      _: "Ziel",
      keep: {
        title: "Ziel bereits festgelegt",
        message:
          "Sie haben bereits ein Ziel festgelegt. Möchten Sie es behalten oder Ihren aktuellen Standort verwenden?",
      },
    },
    personCount: "Personenanzahl",
    currentTime: "Aktuelle Uhrzeit",
    currentDate: "Aktuelles Datum",
    calculatingRoute: "Route wird berechnet",
    currentLocation: "Aktueller Standort",
    failedToGetCurrentLocation: "Fehler beim Abrufen des aktuellen Standorts",
    route: {
      calculation: {
        error: "Routenberechnung fehlgeschlagen",
      },
    },
    place: {
      keep: "Behalten",
      useCurrentLocation: "Aktuellen Standort verwenden",
    },
  },
  price: {
    discount: "Rabatt",
    feedback: {
      _: "Preis Feedback",
      tooHigh: "Zu hoch",
      tooLow: "Zu tief",
    },
    calculation: {
      _: "Preisberechnung",
      error: "Fehler bei der Preisberechnung",
    },
    pricelist: "Preisliste",
  },
  vehicleSelection: {
    companyAndVehicle: "Unternehmen und Fahrzeug",
    company: "Unternehmen",
    vehicle: "Fahrzeug",
    vehicleModel: "Modell",
    noCompanies: "Keine Unternehmen verfügbar",
    noVehicles: "Keine Fahrzeuge verfügbar",
    noCompany: "Kein Unternehmen",
    noVehicle: "Kein Fahrzeug",
    availableCompanies: {
      error: "Konnte verfügbare Unternehmen nicht laden",
    },
    availableVehicles: {
      error: "Konnte verfügbare Fahrzeuge nicht laden",
    },
    selectVehicle: {
      error: "Fahrzeugauswahl fehlgeschlagen",
    },
  },
  driverlist: {
    noDrivers: "Keine Fahrer",
    drivers: "Fahrer",
    visibleDrivers: {
      error: "Konnte Fahrer nicht laden",
    },
    noZipcode: "Keine Postleitzahl",
  },
  network: {
    noInternet: "Keine Internetverbindung",
  },
  driver: {
    setStatus: {
      error: "Konnte Status nicht setzen",
    },
    status: {
      notOutofservice: {
        title: "Status ist nicht 'Außer Dienst'",
        message: "Bitte setzen Sie Ihren Status auf 'Außer Dienst' bevor Sie sich abmelden",
      },
    },
    vehicle: {
      noVehicleChosen: {
        title: "Fahrzeug wählen",
        message: "Wählen Sie ein Fahrzeug, um online zu gehen",
      },
    },
  },
  poi: {
    fetch: {
      error: "Konnte Orte nicht laden",
    },
    google: {
      error: "Konnte Google-Orte nicht laden",
    },
  },
  user: {
    info: {
      error: "Konnte Benutzerinformationen nicht laden",
    },
  },
  permissions: {
    geolocation: {
      backgroundPermissionRationale: {
        title: "Erlauben Sie {applicationName}, auf Ihren Standort im Hintergrund zuzugreifen",
        message:
          "Wenn Sie Standortdienste immer zulassen, wird sichergestellt, dass Ihre Position auch dann zuverlässig geteilt werden kann, wenn Mooovex im Hintergrund läuft.",
      },
      denied: "Öffnen Sie die Anwendungseinstellungen und ändern Sie die Berechtigung manuell",
      rationale: {
        title: "Die Aktivierung von Standortfunktionen ermöglicht uns",
        features: {
          shareLocation: "Ihren Standort mit Fahrern in ihrem Unternehmen zu teilen",
          assignRides: "Ihnen Fahrten in Ihrer Nähe zuzuweisen",
          calculateRoutes: "Die optimalsten Routen für Ihre Fahrten zu berechnen",
        },
        backgroundInfo:
          "Wenn Sie außerdem den Standortzugriff zulassen, während die App im Hintergrund ausgeführt wird, wird die Zuverlässigkeit der oben genannten Funktionen erheblich verbessert, wenn Ihr Bildschirm ausgeschaltet ist oder Sie eine andere App im Vordergrund haben.",
        changeLater: "Sie können diese Option später in den Einstellungen ändern",
      },
      alwaysWarning: `Um Ihren Standort zu teilen, setzen Sie bitte die Standortberechtigung auf "Immer"`,
    },
    openSettings: "Einstellungen öffnen",
    allowBackground: "Zu '{backgroundPermissionOptionLabel}' ändern",
  },
  taxistations: "Taxistände",
  geolocation: {
    notification: {
      title: "Positions-Tracking",
      message:
        "Mooovex is zeichnet Ihre Position auf. Um das Positions-Tracking zu deaktivieren, setzten Sie ihren Status auf 'Außer Dienst'",
    },
    get: {
      failed: "Standort konnte nicht ermittelt werden",
    },
    enableInSettings: "Aktivieren Sie Ortungsdienste in den Einstellungen",
  },
  devices: {
    _: "Geräte",
    noDevices: "Keine Geräte",
    thisDevice: "Dieses Gerät",
    override: {
      title: "Fahrzeugposition mit diesem Gerät senden?",
      message:
        "Derzeit sendet ein anderes Gerät, das mit Ihrem Konto verbunden ist, den Standort des Fahrzeugs. Möchten Sie, dass Ihr Gerät den Standort des Fahrzeugs anstelle des anderen Geräts sendet?",
    },
  },
  transportcompany: {
    logout: { _: "Von Unternehmen abmelden", fail: "Abmeldung Fehlgeschlagen" },
    failedToChoose: "Fehler beim Auswählen des Unternehmens",
    failedToUpdateGlobalStatusFilter: "Fehler beim Aktualisieren des globalen Statusfilters",
    failedToUpdateVisibility: "Fehler beim Aktualisieren der Sichtbarkeit",
  },
  vehicle: {
    deselect: "Fahrzeug freigeben",
  },
  updates: {
    new: {
      title: (version: string) => `Update ${version} verfügbar`,
      message:
        "Ein neues Update ist verfügbar, die App muss neu gestartet werden, um dieses Update herunterzuladen und zu installieren. Jetzt neu starten?",
    },
    appStore: {
      title: "App Store Update verfügbar",
      message: (versionString: string) => `Bitte laden Sie Version ${versionString} vom App Store herunter`,
      button: "App Store öffnen",
    },
    searchingForUpdates: "Suche nach Updates",
    downloadingUpdate: (version: string) => `Update ${version} wird heruntergeladen`,
    installingUpdate: (version: string) => `Update ${version} wird installiert`,
  },
  place: {
    search: {
      favorites: "Favoriten",
      history: "Verlauf",
      results: "Suchergebnisse",
    },
    airportHotelAddress: "Flughafen, Hotel, Adresse...",
    couldNotFindPlaceName: "Konnte den Ortsnamen nicht finden",
    failedToGetDetails: "Konnte Ortsdaten nicht laden",
    failedToGetPlaces: "Konnte Orte nicht laden",
  },
  service: "Dienst",
  settings: {
    _: "Einstellungen",
    biometrics: {
      _: "Biometrie",
      setup: {
        title: "Biometrie einrichten",
        message: "Durch Einrichten der biometrischen Verifizierung können Sie sich ohne Ihr Passwort anmelden",
      },
      logoutForBiometricSetup: "Sie werden abgemeldet, um die biometrische Verifizierung einzurichten",
      deleteCredentials: "Biometrische Anmeldeinformationen löschen",
      failedToSaveBiometricCredentials: "Biometrische Anmeldeinformationen konnten nicht gespeichert werden",
      savedCredentials: "Anmeldeinformationen gespeichert",
      verificationFailed: "Verifizierung fehlgeschlagen",
      noCredentialsFound: "Keine Anmeldeinformationen gefunden",
      loginWithBiometrics: "Mit Biometrie Anmelden",
      delete: {
        title: "Möchten Sie Ihre biometrischen Anmeldeinformationen löschen?",
        message: "Sie können sich nicht mehr mit Biometrie anmelden",
        confirm: "Biometrische Anmeldeinformationen gelöscht",
        failed: "Biometrische Anmeldeinformationen konnten nicht gelöscht werden",
      },
    },
    replaceLocation: "Standort ersetzen beim Fahrt Start/Stopp",
    driverstatusInDropdown: "Status Auswahl im unteren App-Menü",
    rideFormLandscapeRightSide: "Formular im Querformat rechts anzeigen",
    largerVehicleIcons: "Große Fahrzeug-Marker",
    hideConcludeConfirm: "Bestätigung beim Fahrtende verstecken",
    debugMode: "Debug-Modus",
  },
  locale: {
    failedToChange: "Ändern der Sprache fehlgeschlagen",
  },
  transfers: {
    transferCreateError: "Transfer konnte nicht erstellt werden",
    waypointCreateError: "Waypoint konnte nicht erstellt werden",
    transferPauseError: "Transfer konnte nicht pausiert werden",
    transferResumeError: "Transfer konnte nicht fortgesetzt werden",
    waypointSetWaitTimeError: "Wartezeit konnte nicht gesetzt werden",
    transferConcludeError: "Transfer konnte nicht abgeschlossen werden",
    transferAbortError: "Transfer konnte nicht abgebrochen werden",
    routeSegmentGenerateError: "Routensegment konnte nicht generiert werden",
    delete: {
      confirm: {
        title: "Transfer löschen",
        message: "Sind Sie sicher, dass Sie diesen Transfer löschen möchten?",
      },
      error: "Transfer konnte nicht gelöscht werden",
    },
    saved: "Transfer wurde gespeichert",
    deleted: "Transfer wurde gelöscht",
  },
  transferSummary: {
    paymentMethod: {
      cash: "Bar",
      card: "Karte",
      invoice: "Rechnung",
    },
    comments: "Kommentare",
    testMode: "Testmodus",
    client: "Kunde",
    invoiceTo: "Rechnung an",
    priceOptions: {
      _: "Preis Optionen",
      feedback: {
        _: "Preis Feedback",
        tooHigh: "Zu hoch",
        tooLow: "Zu niedrig",
      },
      actualPrice: "Wirklicher Preis",
      description:
        "Markieren Sie, ob der Preis zu hoch oder zu niedrig ist, und geben Sie optional einen korrigierten Fahrpreis für erhebliche Abweichungen ein. Ihr Feedback hilft uns, die Preisgenauigkeit zu verbessern",
    },
    open: "Zusammenfassung öffnen",
  },
  partnerships: {
    _: "Partnerschaften",
    description:
      "Verwalten Sie Ihre Partnerschaften zur gemeinsamen Nutzung von GPS-Daten mit anderen Unternehmen und Gruppen",
    noPartnerships: "Keine Partnerschaften",
    active: "Aktiv",
    inactive: "Inaktiv",
    sender: "Sender",
    status: "Status",
    receiver: "Empfänger",
    any: "Alle",
    shareMyPositionWith: "Teile meine Position mit",
    sharesPositionWithMe: "Teilt Position mit mir",
    partner: "Partner",
    request: "Anfragen",
    share: "Teilen",
    editPartnership: "Partnerschaft bearbeiten",
    shareStatus: "Teile Status",
    failedToUpdatePartnership: "Partnerschaft konnte nicht aktualisiert werden",
    closePartnership: "Enge Partnerschaft",
    oneStatusRequired: "Mindestens ein Status ist erforderlich",
    globalStatus: "Globaler Status",
    globalStatusDescription: "Status, der geteilt wird, wenn globale Sichtbarkeit ausgewählt ist",
  },
  driverlocations: {
    failedToFetch: "Fehler beim Abrufen der Fahrerpositionen",
  },
};
