import { makePersisted } from "@solid-primitives/storage";
import mooovexApiClient, { InferResponseType } from "mooovex-api-client";
import { createContext, ParentComponent, useContext } from "solid-js";
import { createStore, reconcile, SetStoreFunction } from "solid-js/store";

export type Driverlocation = InferResponseType<
  typeof mooovexApiClient.bearerClient.v2.driverlocations.$get,
  200
>[number];

type DriverlocationsStoreType = Record<string, Driverlocation>;
const [driverlocations, setDriverlocations] = makePersisted(createStore<DriverlocationsStoreType>({}), {
  name: "mooovex:driverlocations",
});

type Context = {
  driverlocations: DriverlocationsStoreType;
  setDriverlocations: SetStoreFunction<DriverlocationsStoreType>;
  driverlocationsArray: () => Driverlocation[];
  startPolling: () => void;
  stopPolling: () => void;
};

export const DriverlocationsContext = createContext<Context>();

type Props = {
  showErrorMessage: (message: string) => void;
  viewAsTransportcompanyId?: number;
};

let pollingInterval: ReturnType<typeof setInterval> | undefined;

export const DriverlocationsProvider: ParentComponent<Props> = (props) => {
  return (
    <DriverlocationsContext.Provider
      value={{
        driverlocations: driverlocations,
        setDriverlocations: setDriverlocations,
        driverlocationsArray: () => Object.values(driverlocations),
        startPolling: () => {
          if (pollingInterval === undefined) {
            fetchDriverlocations();
            pollingInterval = setInterval(fetchDriverlocations, 5000);
          }
        },
        stopPolling: () => {
          if (pollingInterval !== undefined) {
            clearInterval(pollingInterval);
            pollingInterval = undefined;
          }
        },
      }}
    >
      {props.children}
    </DriverlocationsContext.Provider>
  );

  function fetchDriverlocations() {
    if (props.viewAsTransportcompanyId === undefined) {
      setDriverlocations(reconcile({}));
      return;
    }

    mooovexApiClient.bearerClient.v2.driverlocations
      .$get({ query: { viewAsTransportcompanyId: props.viewAsTransportcompanyId.toString() } })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            const reduced = data.reduce<DriverlocationsStoreType>((prev, curr) => {
              prev[curr.driverId] = curr;
              return prev;
            }, {});

            setDriverlocations(reconcile(reduced));
          });
        } else {
          console.error(new Error("Failed to fetch driverlocations", { cause: response }));
        }
      })
      .catch((e) => {
        console.error(new Error("Failed to fetch driverlocations", { cause: e }));
      });
  }
};

export function useDriverlocations() {
  const context = useContext(DriverlocationsContext);
  if (!context) throw new Error("You didn't use useDriverlocations inside a DriverlocationsProvider");
  return context;
}
