import { createEffect, createSelector, For, Show } from "solid-js";
import { useTransferForm } from "../TransferFormProvider";
import { ct } from "../i18n";

type PriceDiscountPreset = { name: string; discount: number } | "default";

const presets: PriceDiscountPreset[] = [
  "default",
  { name: "-10%", discount: -0.1 },
  { name: "Diurno (-20%)", discount: -0.2 },
];
export function PriceOptionsDiscount() {
  const [transferForm, setTransferForm] = useTransferForm();
  const isSelected = createSelector(() => transferForm.form.priceOptionsSelectedPresetIndex);

  createEffect(() => {
    const selectedPreset = presets[transferForm.form.priceOptionsSelectedPresetIndex];
    if (typeof selectedPreset === "object") {
      setTransferForm("form", "discount", selectedPreset.discount);
    } else if (selectedPreset === "default") {
      setTransferForm("form", "discount", 0);
    }
  });

  return (
    <div class="btn-group">
      <For each={presets}>
        {(preset, i) => (
          <button
            class="btn"
            classList={{
              "btn-border-primary": !isSelected(i()),
              "btn-primary": isSelected(i()),
            }}
            onclick={() => setTransferForm("form", "priceOptionsSelectedPresetIndex", i())}
          >
            {typeof preset === "object" ? preset.name : <Show when={preset === "default"}>{ct.common.normal()}</Show>}
          </button>
        )}
      </For>
    </div>
  );
}
