import { Map } from "maplibre-gl";
import { createContext, ParentProps, useContext } from "solid-js";

const SolidMapContext = createContext<Map>();

export default function SolidMapProvider(props: ParentProps<{ map: Map }>) {
  return <SolidMapContext.Provider value={props.map}>{props.children}</SolidMapContext.Provider>;
}

export function useSolidMap() {
  const map = useContext(SolidMapContext);
  if (!map) throw new Error("You didn't use useSolidMap inside a SolidMapProvider");
  return map;
}
