import { XIcon } from "lucide-solid";
import { Component } from "solid-js";
import { useTransferForm } from "../TransferFormProvider";

type Props = {};

export const ClearRideFormButton: Component<Props> = (props) => {
  const [, , { clearForm }] = useTransferForm();

  return (
    <button class="btn btn-light p-2" onclick={clearForm}>
      <XIcon size={32} />
    </button>
  );
};
