import { MinusIcon, PlusIcon } from "lucide-solid";
import { Component } from "solid-js";
import { useSolidMap } from "solid-map";

type Props = {};

export const MapZoomControl: Component<Props> = (props) => {
  const map = useSolidMap();

  return (
    <div class="btn-group-vertical" style={{ height: "82px" }}>
      <button
        type="button"
        class="btn btn-border-dark bg-white shadow p-0"
        onclick={() => map.zoomIn()}
        style={{ height: "41px", width: "41px" }}
      >
        <PlusIcon />
      </button>
      <button
        type="button"
        class="btn btn-border-dark bg-white shadow p-0"
        onclick={() => map.zoomOut()}
        style={{ height: "41px", width: "41px" }}
      >
        <MinusIcon />
      </button>
    </div>
  );
};
