import { makePersisted } from "@solid-primitives/storage";
import { Accessor, Setter, createEffect, createSignal, on } from "solid-js";
import { userSignal } from "supabase-client";

class UserStorage {
  constructor(private prefix: string) {}

  private getKey(key: string): string | undefined {
    const user = userSignal();
    if (!user) {
      return;
    }
    return `${this.prefix}:${key}@${user.id}`;
  }

  setItem(key: string, value: string): void {
    const newKey = this.getKey(key);
    if (!newKey) return;
    return localStorage.setItem(newKey, value);
  }

  getItem(key: string): string | null {
    const newKey = this.getKey(key);
    if (!newKey) return null;
    return localStorage.getItem(newKey);
  }

  clear(): void {
    return localStorage.clear();
  }

  key(index: number): string | null {
    return localStorage.key(index);
  }

  removeItem(key: string): void {
    const newKey = this.getKey(key);
    if (!newKey) return;
    return localStorage.removeItem(newKey);
  }

  get length() {
    return localStorage.length;
  }
}

export const userStorage = new UserStorage("mooovex");
export let trackedUserSignals = new Map<string, (user: ReturnType<typeof userSignal>) => void>();

export function createUserStorageSignal<T>(name: string, initialValue?: T) {
  let signal = createSignal(initialValue);
  const accessor = (() => signal[0]()) as Accessor<T>;
  const setter = ((value: any) => signal[1](value)) as Setter<T>;

  trackedUserSignals.set(name, (user) => {
    if (user) {
      signal = makePersisted(signal, {
        storage: userStorage,
        name,
      });
    } else {
      signal[1](undefined);
    }
  });

  return [accessor, setter] satisfies [Accessor<T>, Setter<T>];
}

createEffect(
  on(userSignal, (user) => {
    trackedUserSignals.forEach((callback) => callback(user));
  })
);

export const [lastUsedEmail, setLastUsedEmail] = makePersisted(createSignal<string>(), {
  name: "mooovex:login.email.lastUsed",
});
