import { ResponseBodyItem as GHResponseItem, responseBodyItem as ghResponseItem } from "./autocomplete.api.schema";
import { responseBodyItem as googleResponseItem } from "./google.autocomplete.api.schema";
import { responseBody as googleResponse } from "./google.place_details.api.schema";
import { z } from "./openapizod";

export function isGh(value: AutocompleteItem | Place): value is GHResponseItem {
  return "osm_id" in value;
}

export function getId(value: Place | AutocompleteItem): string | number {
  return isGh(value) ? value.osm_id : value.google_place_id;
}

export function getCoordinates(value: Place) {
  return isGh(value) ? value.point : value.location.coordinates;
}

export function getAddress(value: Place | AutocompleteItem) {
  return isGh(value)
    ? [value.street, value.housenumber, value.city, value.postcode, value.state, value.country]
        .filter((v) => typeof v !== "undefined")
        .join(", ")
    : value.formatted_address;
}

export const autocompleteItem = ghResponseItem.or(googleResponseItem);
export const place = ghResponseItem.or(googleResponse);

export type AutocompleteItem = z.infer<typeof autocompleteItem>;
export type Place = z.infer<typeof place>;

export function removeCommonAddressParts(
  places: {
    name: string;
    country?: string | undefined;
    countrycode?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    street?: string | undefined;
    housenumber?: string | undefined;
    postcode?: string | undefined;
  }[]
): string[][] {
  const propertyOrder: (keyof Place)[] = ["country", "state", "postcode", "city", "street", "housenumber"];

  // Find the first differing property
  let firstDifferingIndex = propertyOrder.findIndex(
    (prop) => !places.every((place) => place[prop] === places[0][prop])
  );

  // If all properties are the same, start from the street
  if (firstDifferingIndex === -1) {
    firstDifferingIndex = propertyOrder.indexOf("street");
  }

  // Format addresses
  return places.map((place) =>
    propertyOrder
      .slice(firstDifferingIndex)
      .map((prop) => place[prop])
      .filter((v) => v !== undefined)
  );
}
