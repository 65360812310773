import { GeoJSONSource, GeoJSONSourceSpecification } from "maplibre-gl";
import { createContext, createEffect, createUniqueId, onCleanup, onMount, ParentProps, useContext } from "solid-js";
import { useSolidMap } from "./SolidMapProvider";

const SolidMapGeoJSONSourceContext = createContext<string>();

function SolidMapGeoJSONSourceProvider(props: ParentProps<{ sourceId: string }>) {
  return (
    <SolidMapGeoJSONSourceContext.Provider value={props.sourceId}>
      {props.children}
    </SolidMapGeoJSONSourceContext.Provider>
  );
}

export function useSolidMapGeoJSONSource() {
  const sourceId = useContext(SolidMapGeoJSONSourceContext);
  if (!sourceId) throw new Error("You didn't use useSolidMapSource inside a SolidMapSourceProvider");
  return sourceId;
}

export default function SolidMapGeoJSONSource(
  localProps: ParentProps<{ sourceSpecification: GeoJSONSourceSpecification }>
) {
  const id = createUniqueId();

  const map = useSolidMap();

  onMount(() => {
    map.addSource(id, localProps.sourceSpecification);
  });

  createEffect(
    () => {
      (map.getSource(id) as GeoJSONSource).setData(localProps.sourceSpecification.data);
    },
    { defer: true }
  );

  onCleanup(() => {
    map.removeSource(id);
  });

  return <SolidMapGeoJSONSourceProvider sourceId={id}>{localProps.children}</SolidMapGeoJSONSourceProvider>;
}
