import {
  DragDropProvider,
  DragDropSensors,
  DragEventHandler,
  DragOverlay,
  SortableProvider,
  closestCenter,
} from "@thisbeyond/solid-dnd";
import { Component, For, Show, createSignal } from "solid-js";
import { ct } from "../i18n";
import { PlaceSearchContainer } from "../placeSearch/PlaceSearchContainer";
import { PlaceSearchContainerOverlay } from "../placeSearch/PlaceSearchContainerOverlay";
import { useTransferForm } from "../TransferFormProvider";
import { DragDropConstrainAxis } from "./DragDropConstrainAxis";

type Props = {
  disabled?: boolean;
};

export const DragAndDropPlaces: Component<Props> = (props) => {
  const [transferForm, setTransferForm] = useTransferForm();

  const [activeContainerId, setActiveContainerId] = createSignal<string>();

  const placeContainerIds = () => transferForm.form.placeContainers.map((c) => c.containerId);

  const onDragStart: DragEventHandler = ({ draggable }) => setActiveContainerId(draggable.id + "");

  const onDragEnd: DragEventHandler = ({ draggable, droppable }) => {
    if (draggable && droppable) {
      const currentItems = transferForm.form.placeContainers;
      const fromIndex = currentItems.findIndex((o) => o.containerId === draggable.id);
      const toIndex = currentItems.findIndex((o) => o.containerId === droppable.id);
      if (fromIndex !== toIndex) {
        const updatedItems = currentItems.slice();
        updatedItems.splice(toIndex, 0, ...updatedItems.splice(fromIndex, 1));
        setTransferForm("form", "placeContainers", updatedItems);
      }
    }
  };

  return (
    <DragDropProvider onDragEnd={onDragEnd} onDragStart={onDragStart} collisionDetector={closestCenter}>
      <div class="d-flex gap-2 align-items-end">
        <DragDropSensors />
        <DragDropConstrainAxis axis="x" />
        <div class="d-flex flex-column gap-2 flex-grow-1 overflow-hidden">
          <SortableProvider ids={placeContainerIds()}>
            <For each={transferForm.form.placeContainers}>
              {(placeContainer) => {
                return (
                  <PlaceSearchContainer
                    containerId={placeContainer.containerId}
                    place={placeContainer.place}
                    placeholder={ct.place.airportHotelAddress()}
                    disabled={props.disabled}
                  />
                );
              }}
            </For>
          </SortableProvider>
        </div>
        <DragOverlay style={{ "z-index": 55 }}>
          <Show when={activeContainerId()}>
            {(containerId) => (
              <PlaceSearchContainerOverlay
                containerId={containerId()}
                place={transferForm.form.placeContainers.find((c) => c.containerId === containerId())?.place}
                placeholder={ct.place.airportHotelAddress()}
                disabled={props.disabled}
              />
            )}
          </Show>
        </DragOverlay>
      </div>
    </DragDropProvider>
  );
};
