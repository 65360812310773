import { Component, For } from "solid-js";
import { useDriverlocations } from "./DriverlocationsProvider";
import { ct } from "./i18n";
import { IdentifierBadge } from "./vehicles";

type Props = {
  onDriverClick?: (driver_id: string) => void;
};

export const Driverlist: Component<Props> = (props) => {
  const { driverlocationsArray } = useDriverlocations();

  const zipcodes = () => {
    const zipcodesSet = new Set<number>();
    const zipcodesArray: Array<{ zipcodeId: number; zipcode: string; municipiality: string }> = [];

    for (const driverlocation of driverlocationsArray()) {
      if (!zipcodesSet.has(driverlocation.vehicleZipcodeId)) {
        zipcodesSet.add(driverlocation.vehicleZipcodeId);
        zipcodesArray.push({
          zipcodeId: driverlocation.vehicleZipcodeId,
          zipcode: driverlocation.vehicleZipcode,
          municipiality: driverlocation.vehicleMunicipiality,
        });
      }
    }

    return zipcodesArray.sort((a, b) => parseInt(a.zipcode) - parseInt(b.zipcode));
  };

  return (
    <table class="table table-sm">
      <For each={zipcodes()} fallback={<div class="text-center">{ct.driverlist.noDrivers()}</div>}>
        {(zipcode) => {
          const zipcodeDrivers = () =>
            driverlocationsArray()
              .filter((driverlocation) => driverlocation.vehicleZipcodeId === zipcode.zipcodeId)
              .sort((a, b) => parseInt(a.vehicleIdentifier) - parseInt(b.vehicleIdentifier));

          return (
            <>
              <thead>
                <tr>
                  <th></th>
                  <th class="fw-bold text-primary">{zipcode?.municipiality ?? ct["driverlist.noZipcode"]()}</th>
                  <th class="text-center">
                    <i class="bi-people-fill" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <For each={zipcodeDrivers()}>
                  {(driver) => {
                    return (
                      <>
                        <tr class="cursor-pointer align-middle">
                          <td onclick={() => props.onDriverClick?.(driver.driverId)}>
                            <IdentifierBadge value={driver.vehicleIdentifier} status={driver.status} />
                          </td>
                          <td class="fw-bold text-nowrap w-100" onclick={() => props.onDriverClick?.(driver.driverId)}>
                            {driver.driverName}
                          </td>
                          <td class="text-end" onclick={() => props.onDriverClick?.(driver.driverId)}>
                            {driver.vehicleSeats}
                          </td>
                          <td>
                            <button
                              class="btn btn-light btn-sm"
                              title={`${driver.phoneNumber} (${ct["common.rightClickToCopy"]()})`}
                              onclick={() => window.open(`tel:${driver.phoneNumber}`)}
                              oncontextmenu={async (e) => {
                                e.preventDefault();
                                await navigator.clipboard.writeText(driver.phoneNumber);
                              }}
                            >
                              <i class="bi-telephone-fill text-primary" />
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  }}
                </For>
              </tbody>
            </>
          );
        }}
      </For>
    </table>
  );
};
