import { makePersisted } from "@solid-primitives/storage";
import { Component, For, Match, Switch, createEffect, createSelector, createSignal } from "solid-js";
import { F } from "../formatters";
import { useTransferForm } from "../TransferFormProvider";

type Props = {
  max: number;
  min: number;
  step: number;
};

type PriceDiscountPreset = { name: string; multiplier: number } | "default" | "custom";

const presets: PriceDiscountPreset[] = ["default", { name: "!!!Nightly", multiplier: 1.1 }, "custom"];

export const PriceOptionsOverlay: Component<Props> = (props) => {
  const [transferForm, setTransferForm] = useTransferForm();
  const [selectedPresetIndex, setSelectedPresetIndex] = makePersisted(createSignal<number>(0), {
    name: "mooovex:currentRide.priceOptions.selectedPresetIndex",
  });
  const [customDiscount, setCustomDiscount] = makePersisted(createSignal<number>(0), {
    name: "mooovex:currentRide.priceOptions.customDiscount",
  });
  const isSelected = createSelector(selectedPresetIndex);

  createEffect(() => {
    const selectedPreset = presets[selectedPresetIndex()];
    if (typeof selectedPreset === "object") {
      setTransferForm("form", "discount", selectedPreset.multiplier);
    } else if (selectedPreset === "default") {
      setTransferForm("form", "discount", 0);
    } else {
      setTransferForm("form", "discount", customDiscount());
    }
  });

  function changePercentage(by: number) {
    if (customDiscount() + by < props.min) setCustomDiscount(props.min);
    else if (customDiscount() + by > props.max) setCustomDiscount(props.max);
    else setCustomDiscount(customDiscount() + by);
  }

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        "z-index": 4,
        "background-color": "white",
        transition: "transform 150ms ease-in-out",
        transform: `translateY(${transferForm.priceOptionsVisible ? "0" : "100%"})`,
        overflow: "hidden",
        padding: "6px",
      }}
    >
      <div class="d-flex gap-2">
        <button
          class="btn btn-border-dark bi-arrow-down mb-2"
          onclick={() => {
            setTransferForm("priceOptionsVisible", false);
          }}
        />
      </div>
      <div class="d-flex gap-2">
        <div class="btn-group-vertical flex-grow-1">
          <For each={presets}>
            {(preset, i) => (
              <button
                class="btn"
                classList={{
                  "btn-border-primary": !isSelected(i()),
                  "btn-primary": isSelected(i()),
                }}
                onclick={() => setSelectedPresetIndex(i())}
              >
                {typeof preset === "object" ? (
                  preset.name
                ) : (
                  <Switch>
                    <Match when={preset === "default"}>!!!Default</Match>
                    <Match when={preset === "custom"}>!!!Custom</Match>
                  </Switch>
                )}
              </button>
            )}
          </For>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center mx-3 gap-1"
          classList={{
            "grayed-out": presets[selectedPresetIndex()] !== "custom",
          }}
        >
          <button
            class="btn btn-border-dark bi-caret-up-fill"
            onclick={() => changePercentage(props.step)}
            disabled={customDiscount() >= props.max}
          />
          <div class="form-control form-control-lg">{F.percent(customDiscount())}</div>
          <button
            class="btn btn-border-dark bi-caret-down-fill"
            onclick={() => changePercentage(-props.step)}
            disabled={customDiscount() <= props.min}
          />
        </div>
      </div>
    </div>
  );
};
