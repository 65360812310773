import { debounce } from "@solid-primitives/scheduled";
import { LngLatBounds, LngLatBoundsLike, LngLatLike } from "maplibre-gl";
import * as API from "mooovex-api-schema";
import { Component, createEffect, on, onCleanup, onMount } from "solid-js";
import { useSolidMap } from "solid-map";
import { useTransferForm } from "../TransferFormProvider";

type Props = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

export const MapAnimations: Component<Props> = (props) => {
  const map = useSolidMap();
  const [transferForm, setTransferForm, { placesInWaypoints }] = useTransferForm();

  onMount(() => {
    map.on("dragstart", onDragstart);
  });
  onCleanup(() => {
    map.off("dragstart", onDragstart);
  });

  createEffect(() => {
    map.setPadding({
      top: props.top ?? 0,
      right: props.right ?? 0,
      bottom: props.bottom ?? 0,
      left: props.left ?? 0,
    });
  });

  function onDragstart() {
    uncenterDriver();
  }

  function uncenterDriver() {
    setTransferForm("centeredDriverId", undefined);
  }

  const debouncedAnimations = debounce(() => {
    const boundsOptions: maplibregl.FitBoundsOptions = {
      padding: 40,
      maxZoom: 15,
    };

    if (placesInWaypoints().length === 1) {
      uncenterDriver();
      const place = placesInWaypoints()[0];
      const point = API.PlaceAdapter.getCoordinates(place) as LngLatLike;
      map.flyTo({
        center: point,
        zoom: 15,
        ...boundsOptions,
      });
    } else if (placesInWaypoints().length > 1) {
      uncenterDriver();

      const bounds = new LngLatBounds();

      for (const place of placesInWaypoints()) {
        const point = API.PlaceAdapter.getCoordinates(place) as LngLatLike;
        bounds.extend(point);
      }

      let route: API.route.ResponseBody | undefined;

      if (transferForm.form.route) {
        bounds.extend(transferForm.form.route.bbox as LngLatBoundsLike);
      }

      map.fitBounds(bounds, boundsOptions);
    }
  }, 300);

  createEffect(
    on([placesInWaypoints, () => transferForm.form.route], debouncedAnimations, {
      defer: true,
    })
  );

  return null;
};
