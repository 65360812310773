import { PlusCircle } from "lucide-solid";
import { batch, Component } from "solid-js";
import { useTransferForm } from "../TransferFormProvider";

type Props = {
  disabled?: boolean;
  maxPlaceCount?: number;
};

export const PlaceAddButton: Component<Props> = (props) => {
  const [transferForm, setTransferForm, { addPlaceContainer }] = useTransferForm();

  return (
    <button
      disabled={
        props.disabled || !!(props.maxPlaceCount && transferForm.form.placeContainers.length >= props.maxPlaceCount)
      }
      class="btn btn-light p-2"
      onclick={() =>
        batch(() => {
          const containerId = addPlaceContainer();
          setTransferForm("form", "placeSearchTargetContainerId", containerId);
          setTransferForm("placeSearchVisible", true);
        })
      }
    >
      <PlusCircle size={32} />
    </button>
  );
};
