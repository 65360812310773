import {
  CircleLayerSpecification,
  LayerSpecification,
  LineLayerSpecification,
  SymbolLayerSpecification,
} from "maplibre-gl";
import { createUniqueId, onCleanup, onMount } from "solid-js";
import { useSolidMap } from "./SolidMapProvider";
import { useSolidMapGeoJSONSource } from "./SolidMapSource";
type Spec = CircleLayerSpecification | LineLayerSpecification | SymbolLayerSpecification;

export default function SolidMapLayer(localProps: { layerSpecification: Omit<Spec, "id" | "source"> }) {
  const map = useSolidMap();
  const sourceId = useSolidMapGeoJSONSource();

  const id = createUniqueId();

  onMount(() => {
    map.addLayer({ ...localProps.layerSpecification, id: id, source: sourceId } as LayerSpecification);
  });

  onCleanup(() => {
    map.removeLayer(id);
  });

  return null;
}
