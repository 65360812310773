import { Component, Show } from "solid-js";
import { F } from "../formatters";
import { useTransferForm } from "../TransferFormProvider";

type Props = {
  disabled?: boolean;
};

export const StartTimeButton: Component<Props> = (props) => {
  const [transferForm, , { showTimePicker }] = useTransferForm();

  return (
    <button
      disabled={props.disabled}
      class="btn btn-lg flex-grow-1 flex-nowrap fw-bold"
      classList={{
        "btn-border-dark": !transferForm.form.startTime,
        "btn-primary": !!transferForm.form.startTime,
      }}
      onclick={() => showTimePicker(transferForm.form.startTime)}
    >
      <i class="bi-clock" />
      <Show when={transferForm.form.startTime}>
        {(time) => <span class="ms-1">{F.time(time().getMyTimestamp().getMyTime())}</span>}
      </Show>
    </button>
  );
};
