import { Buffer, wkx } from "wkx";
import { z } from "zod";
import { MyCoordinates3D } from "./MyCoordinates3D.class";
import { Clonable, Serializable } from "./utils";

export class MyCoordinates2D implements Serializable<[number, number]>, Clonable<MyCoordinates2D> {
  static serialized = z.tuple([z.number().min(-180).max(180), z.number().min(-90).max(90)]);

  constructor(
    public lng: number,
    public lat: number
  ) {}

  static fromJSON(value: [number, number]): MyCoordinates2D {
    return MyCoordinates2D.fromLngLat(value);
  }

  static fromLngLat(value: [number, number]): MyCoordinates2D {
    return new MyCoordinates2D(value[0], value[1]);
  }

  static fromLatLng(value: [number, number]): MyCoordinates2D {
    return new MyCoordinates2D(value[1], value[0]);
  }

  static fromLngLatString(value: string): MyCoordinates2D {
    const [lng, lat] = value
      .replaceAll(" ", "")
      .split(",")
      .map((v) => parseFloat(v));
    return new MyCoordinates2D(lng, lat);
  }

  static fromLatLngString(value: string): MyCoordinates2D {
    const [lat, lng] = value
      .replaceAll(" ", "")
      .split(",")
      .map((v) => parseFloat(v));
    return new MyCoordinates2D(lng, lat);
  }

  static fromGoogleLatLng(value: { longitude: number; latitude: number }): MyCoordinates2D {
    return new MyCoordinates2D(value.longitude, value.latitude);
  }

  static fromMyCoordinates3D(value: MyCoordinates3D) {
    return new MyCoordinates2D(value.lng, value.lat);
  }

  static fromWkx(value: wkx.Point) {
    return new MyCoordinates2D(value.x, value.y);
  }

  static fromWkb(value: Buffer | string) {
    if (typeof value === "string") value = Buffer.from(value, "hex");
    return MyCoordinates2D.fromWkx(wkx.Geometry.parse(value) as wkx.Point);
  }

  toLngLat() {
    return [this.lng, this.lat] satisfies [number, number];
  }

  toLatLng() {
    return [this.lat, this.lng] satisfies [number, number];
  }

  toLngLatString(fractionDigits?: number) {
    return fractionDigits !== undefined
      ? `${this.lng.toFixed(fractionDigits)},${this.lat.toFixed(fractionDigits)}`
      : `${this.lng},${this.lat}`;
  }

  toLatLngString(fractionDigits?: number) {
    return fractionDigits !== undefined
      ? `${this.lat.toFixed(fractionDigits)},${this.lng.toFixed(fractionDigits)}`
      : `${this.lat},${this.lng}`;
  }

  toGoogleLocation() {
    return {
      latLng: {
        longitude: this.lng,
        latitude: this.lat,
      },
    };
  }

  toWkb() {
    return this.toWkx().toWkb();
  }

  toGeoJSON() {
    return {
      type: "Point",
      coordinates: this.toLngLat(),
    } as const;
  }

  clone(): MyCoordinates2D {
    return new MyCoordinates2D(this.lng, this.lat);
  }

  toJSON(): [number, number] {
    return this.toLngLat();
  }

  toString() {
    return this.toLatLngString();
  }

  toMyCoordinates3D(alt: number = 0) {
    return MyCoordinates3D.fromMyCoordinates2D(this, alt);
  }

  toWkx() {
    return new wkx.Point(this.lng, this.lat);
  }
}
