import { Component, Show } from "solid-js";
import { useTransferForm } from "../TransferFormProvider";

type Props = {
  disabled?: boolean;
};

export const PassengerCountButton: Component<Props> = (props) => {
  const [transferForm, setTransferForm] = useTransferForm();

  return (
    <button
      disabled={props.disabled}
      class="d-flex btn btn-lg flex-grow-1 gap-1 justify-content-center flex-nowrap fw-bold"
      classList={{
        "btn-border-dark": !transferForm.form.passengerCount,
        "btn-primary": !!transferForm.form.passengerCount,
      }}
      onclick={() => setTransferForm("passengerCountVisible", true)}
    >
      <i class="bi-person-fill" />
      <Show when={transferForm.form.passengerCount}>
        <span>{transferForm.form.passengerCount}</span>
      </Show>
    </button>
  );
};
